<!-- //通讯录模板 -->
<template>
 <div>
    <div class="ntMain__head flexbox"><h2 class="hdtit flex1">通讯录</h2><span class="link" @click="isShowSearchLayer=true"><i class="iconfont icon-choose fs-18"></i></span>
     </div>
     <div class="ntMain__cont flex1 flexbox flex-col flex-alignc flex-justifyc">
    <div class="flexbox flex-alignc flex-col">
     <div class="mb-20"><i class="iconfont icon-dianzan c-26cbfe fs-28"></i> <span class="fs-28 ml-10">搜索一下</span></div>
     <div class="flexbox" style="background:rgba(255,255,255,.8);border-radius:2px;box-shadow:0 1px 2px #d2d2d2;padding:10px 15px;width:400px;">
     <i class="iconfont icon-search"></i><input class="flex1" style="background:none;border:0;margin-left:10px;" placeholder="搜索..." /><i class="iconfont icon-send"></i>
    </div>
      </div>
    </div>

        <v3-layer v-model="isShowSearchLayer"
         title="<i class='iconfont icon-addfriends'></i> 添加朋友" layerStyle="background:#f9f9f9" :area="['450px', '350px']" opacity="0"
          xclose
        >
         <div class="flexbox flex-col">
         <div style="margin-top:50px;padding:15px 70px;">
          <input class="flex1 align-c" style="background:#fff;border:0;border-radius:2px;box-shadow:0 1px 2px #d2d2d2;padding:10px;width:100%;" placeholder="微信号/手机号" /><p class="align-c pad10 fs-12 c-999">我的微信号：xy190310</p>
         </div><div class="mt-30 vui__btn vui__btn-primary" style="margin:0 auto;height:32px;width:150px;">搜索</div>
      </div>
      </v3-layer>
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
export default {
    components: {},
    setup() {
     const data = reactive({ isShowSearchLayer: false,
      })
     return {...toRefs(data),
     }
  }
}
</script>
